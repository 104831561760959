import { apiClient } from '@app/lib'
import { type SugarOption, type SugarOptionsResponse } from '@app/types'
import { useQuery } from '@tanstack/react-query'
import { isEmpty, join } from 'lodash'
import { useSession } from 'next-auth/react'

export const useOptions = ({ options }: { options: SugarOption[] | undefined }) => {
  const session = useSession()

  return useQuery({
    enabled: session.status === 'authenticated' && !isEmpty(options),
    queryKey: ['options', options],
    queryFn: async () => {
      const { data } = await apiClient.get<SugarOptionsResponse>(`/api/options`, {
        params: {
          options: join(options, ',')
        }
      })

      return data?.data
    }
  })
}
