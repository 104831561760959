import { RouterTransition } from '@app/components'
import {
  type ButtonProps,
  type DefaultMantineColor,
  LoadingOverlayProps,
  MantineProvider as BaseMantineProvider,
  type MantineThemeOverride,
  type ModalProps,
  type PaperProps,
  type StepProps,
  type ThemeIconProps,
  type Tuple
} from '@mantine/core'
import { Notifications } from '@mantine/notifications'
import { type NavigationProgressProps } from '@mantine/nprogress'
import { Arimo, Montserrat } from 'next/font/google'
import { type FC, type ReactNode } from 'react'

type ExtendedCustomColors = 'lemon' | 'light-blue' | 'navy' | DefaultMantineColor

declare module '@mantine/core' {
  // noinspection JSUnusedGlobalSymbols
  export interface MantineThemeColorsOverride {
    colors: Record<ExtendedCustomColors, Tuple<string, 10>>
  }
}

const arimo = Arimo({
  style: ['normal', 'italic'],
  subsets: ['latin'],
  weight: ['400', '500', '600']
})
const montserrat = Montserrat({
  style: ['normal', 'italic'],
  subsets: ['latin'],
  weight: ['400', '500', '600']
})

const theme: MantineThemeOverride = {
  colors: {
    lemon: [
      '#F5F4EE',
      '#EBE7D9',
      '#E4DDC3',
      '#E0D5AA',
      '#E2D18F',
      '#E9D070',
      '#F7D44B', // DEFAULT
      '#E3C349',
      '#CFB347',
      '#BCA447'
    ],
    'light-blue': [
      '#F3F6F8',
      '#DDE8EE',
      '#C6DBE8',
      '#ACCFE5',
      '#8FC6E8',
      '#84B8D9',
      '#7BABC9', // DEFAULT
      '#749FBA',
      '#6D94AB',
      '#68899D'
    ],
    navy: [
      '#40496B',
      '#374269',
      '#2D3A68',
      '#233268',
      '#192B6A',
      '#0E226C',
      '#021A70', // DEFAULT
      '#0C1E5E',
      '#132050',
      '#172144'
    ]
  },
  colorScheme: 'light',
  components: {
    Button: {
      defaultProps: {
        color: 'navy',
        radius: 'lg'
      } as ButtonProps
    },
    Modal: {
      defaultProps: {
        centered: true,
        closeOnClickOutside: false,
        closeOnEscape: false,
        padding: 'lg',
        radius: 'lg'
      } as ModalProps
    },
    LoadingOverlay: {
      defaultProps: {
        loaderProps: {
          color: 'light-blue',
          radius: 'lg'
        }
      } as LoadingOverlayProps
    },
    NavigationProgress: {
      defaultProps: {
        color: 'light-blue'
      } as NavigationProgressProps
    },
    Paper: {
      defaultProps: {
        radius: 'lg'
      } as PaperProps
    },
    StepperStep: {
      defaultProps: {
        color: 'navy'
      } as StepProps
    },
    ThemeIcon: {
      defaultProps: {
        color: 'navy',
        radius: 'lg',
        size: 'lg',
        variant: 'filled'
      } as ThemeIconProps
    }
  },
  headings: {
    fontFamily: montserrat.style.fontFamily,
    fontWeight: '800'
  },
  fontFamily: arimo.style.fontFamily,
  primaryShade: 6
}

export interface MantineProviderProps {
  children: ReactNode
}

export const MantineProvider: FC<MantineProviderProps> = ({ children }) => (
  <BaseMantineProvider
    theme={theme}
    withGlobalStyles
    withNormalizeCSS
  >
    <Notifications />
    <RouterTransition />
    {children}
  </BaseMantineProvider>
)
