import * as yup from 'yup'

yup.addMethod(yup.string, 'email', function validateEmail(message, excludeEmptyString = true) {
  return this.matches(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/, {
    message,
    name: 'email',
    excludeEmptyString
  })
})

yup.addMethod(yup.string, 'password', function validatePassword(message, excludeEmptyString = true) {
  return this.matches(/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/, {
    message,
    name: 'password',
    excludeEmptyString
  })
})

yup.addMethod(yup.string, 'phone', function validatePhone(message, excludeEmptyString = true) {
  return this.matches(/^(\+\d{1,2}[-\s.]?)?\(?[0-9]{3}\)?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im, {
    message,
    name: 'phone',
    excludeEmptyString
  })
})

declare module 'yup' {
  interface StringSchema<TType, TContext, TDefault, TFlags> {
    email(message: string, excludeEmptyString?: boolean): this
    password(message: string, excludeEmptyString?: boolean): this
    phone(message: string, excludeEmptyString?: boolean): this
  }
}
