import axios, { AxiosError } from 'axios'
import { includes } from 'lodash'
import Router from 'next/router'

export const apiClient = axios.create({
  responseType: 'json'
})

apiClient.interceptors.response.use(
  (res) => res,
  async (error: AxiosError) => {
    if (includes([401, 403], error.response?.status)) {
      await Router.push('/api/auth/signin')
    }
  }
)
