import { useOptions } from '@app/lib'
import {
  type SugarBankAccountTypeKey,
  type SugarBankAccountTypeOptions,
  type SugarBankAccountTypeValue,
  SugarOptions,
  type SugarSchoolYear,
  type SugarSchoolYearKey,
  type SugarSchoolYearOptions
} from '@app/types'
import { LoadingOverlay } from '@mantine/core'
import { find } from 'lodash'
import { createContext, type FC, type ReactNode, useContext, useMemo } from 'react'

export interface SugarOptionsContextValue {
  getBankAccountType(key: SugarBankAccountTypeKey): SugarBankAccountTypeValue | undefined
  getBankAccountTypeOptions(): SugarBankAccountTypeOptions | undefined
  getSchoolYear(id: SugarSchoolYearKey): SugarSchoolYear | undefined
  getSchoolYearOptions(): SugarSchoolYearOptions | undefined
  options: SugarOptions | undefined
}
export const SugarOptionsContext = createContext({} as SugarOptionsContextValue)

export const useSugarOptionsContext = () => useContext(SugarOptionsContext)

export interface SugarOptionsProviderProps {
  children: ReactNode
}

export const SugarOptionsProvider: FC<SugarOptionsProviderProps> = ({ children }) => {
  const { data, isFetching } = useOptions({
    options: ['bankAccountType', 'schoolYears']
  })
  const value = useMemo<SugarOptionsContextValue>(
    () => ({
      getBankAccountType: (key) => data?.bankAccountType?.[key],
      getBankAccountTypeOptions: () => data?.bankAccountType,
      getSchoolYear: (id) => find(data?.schoolYears, ['id', id]),
      getSchoolYearOptions: () => data?.schoolYears,
      options: data
    }),
    [data]
  )

  return isFetching ? (
    <LoadingOverlay visible />
  ) : (
    <SugarOptionsContext.Provider value={value}>{children}</SugarOptionsContext.Provider>
  )
}
