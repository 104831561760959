import '@app/lib/yup'
import { MantineProvider, ReactQueryProvider, SugarOptionsProvider } from '@app/providers'
import { type AppType } from 'next/app'
import Head from 'next/head'
import { type Session } from 'next-auth'
import { SessionProvider } from 'next-auth/react'

interface PageProps {
  session?: Session
}

const App: AppType<PageProps> = ({ Component, pageProps: { session, ...pageProps } }) => (
  <>
    <Head>
      <meta
        name='viewport'
        content='minimum-scale=1, initial-scale=1, width=device-width'
      />
      <meta
        name='apple-mobile-web-app-title'
        content='Colorado Department of Early Childhood'
      />
      <meta
        name='application-name'
        content='Colorado Department of Early Childhood'
      />
      <link
        rel='apple-touch-icon'
        sizes='57x57'
        href='/apple-icon-57x57.png'
      />
      <link
        rel='apple-touch-icon'
        sizes='60x60'
        href='/apple-icon-60x60.png'
      />
      <link
        rel='apple-touch-icon'
        sizes='72x72'
        href='/apple-icon-72x72.png'
      />
      <link
        rel='apple-touch-icon'
        sizes='76x76'
        href='/apple-icon-76x76.png'
      />
      <link
        rel='apple-touch-icon'
        sizes='114x114'
        href='/apple-icon-114x114.png'
      />
      <link
        rel='apple-touch-icon'
        sizes='120x120'
        href='/apple-icon-120x120.png'
      />
      <link
        rel='apple-touch-icon'
        sizes='144x144'
        href='/apple-icon-144x144.png'
      />
      <link
        rel='apple-touch-icon'
        sizes='152x152'
        href='/apple-icon-152x152.png'
      />
      <link
        rel='apple-touch-icon'
        sizes='180x180'
        href='/apple-icon-180x180.png'
      />
      <link
        rel='icon'
        type='image/png'
        sizes='192x192'
        href='/android-icon-192x192.png'
      />
      <link
        rel='icon'
        type='image/png'
        sizes='32x32'
        href='/favicon-32x32.png'
      />
      <link
        rel='icon'
        type='image/png'
        sizes='96x96'
        href='/favicon-96x96.png'
      />
      <link
        rel='icon'
        type='image/png'
        sizes='16x16'
        href='/favicon-16x16.png'
      />
      <link
        rel='manifest'
        href='/manifest.json'
      />
      <meta
        name='msapplication-TileColor'
        content='#ffffff'
      />
      <meta
        name='msapplication-TileImage'
        content='/ms-icon-144x144.png'
      />
      <meta
        name='theme-color'
        content='#ffffff'
      />
      <link
        rel='preconnect'
        href='https://fonts.gstatic.com'
      />
    </Head>
    <SessionProvider
      // Re-fetch session every 15 minutes
      refetchInterval={15 * 60}
      // Re-fetches session when window is focused
      refetchOnWindowFocus={true}
      session={session}
    >
      <ReactQueryProvider>
        <SugarOptionsProvider>
          <MantineProvider>
            <Component {...pageProps} />
          </MantineProvider>
        </SugarOptionsProvider>
      </ReactQueryProvider>
    </SessionProvider>
  </>
)

// noinspection JSUnusedGlobalSymbols
export default App
